
import { Options, Vue } from "vue-class-component";

@Options({
  data: () => ({
    /**
     * If the modal is shown
     */
    shown: false,
    /**
     * Custom data stored and consumed by parent
     */
    data: null,
  }),
  methods: {
    /**
     * Open the modal
     */
    open(data: unknown): void {
      this.shown = true;
      this.$emit("open");
      this.data = data ?? null;
    },
    /**
     * Close the modal
     */
    close(): void {
      this.shown = false;
      this.data = null;
      this.$emit("close");
    },
    /**
     * On cancel
     */
    onCancel(): void {
      this.$emit("cancel", this.data);
      this.close();
    },
    /**
     * On confirm
     */
    onConfirm(): void {
      this.$emit("confirm", this.data);
      this.close();
    },
  },
})
export default class ConfirmationModal extends Vue {}
