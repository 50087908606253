import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-733abd15"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "container w-75" }
const _hoisted_6 = { class: "card" }
const _hoisted_7 = {
  key: 0,
  class: "form-container"
}
const _hoisted_8 = {
  key: 0,
  class: "course-not-exists-container"
}
const _hoisted_9 = { class: "course-not-exists" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = ["disabled", "onClick"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 2 }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = ["disabled", "onClick"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 2 }
const _hoisted_24 = { key: 3 }
const _hoisted_25 = { key: 4 }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { key: 2 }
const _hoisted_29 = ["disabled", "onClick"]
const _hoisted_30 = { key: 1 }
const _hoisted_31 = { key: 3 }
const _hoisted_32 = ["disabled", "onClick"]
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { key: 1 }
const _hoisted_36 = { key: 2 }
const _hoisted_37 = { key: 0 }
const _hoisted_38 = { key: 1 }
const _hoisted_39 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!
  const _component_LuSpinner = _resolveComponent("LuSpinner")!
  const _component_DetailsHeader = _resolveComponent("DetailsHeader")!
  const _component_DetailsRow = _resolveComponent("DetailsRow")!
  const _component_SelectItem = _resolveComponent("SelectItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Table = _resolveComponent("Table")!
  const _component_EditButtonsGroup = _resolveComponent("EditButtonsGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfirmationModal, {
      ref: "deleteStudentModal",
      onConfirm: _cache[0] || (_cache[0] = ({ id: studentId }) => _ctx.deleteStudent(studentId))
    }, {
      header: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("shared.confirm-removal")), 1)
      ]),
      default: _withCtx(({ data: student }) => [
        student
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t("shared.confirm-remove-thing", {
            item: student.name,
          })), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512),
    _createVNode(_component_ConfirmationModal, {
      ref: "deleteNoteTakerModal",
      onConfirm: _cache[1] || (_cache[1] = 
      ({ id: noteTakerId }) => {
        _ctx.$refs.deleteNoteTakerModal.close();
        _ctx.deleteNoteTaker(noteTakerId);
      }
    )
    }, {
      header: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("course-occurrence.confirm-removal")), 1)
      ]),
      default: _withCtx(({ data: noteTaker }) => [
        noteTaker
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t("course-occurrence.confirm-remove-note-taker", {
            name: noteTaker.name,
          })), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512),
    _createVNode(_component_ConfirmationModal, {
      ref: "sendOfferModal",
      onConfirm: _cache[2] || (_cache[2] = ({ id: noteTakerId }) => _ctx.sendOffer(noteTakerId))
    }, {
      header: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("course-occurrence.offer.sendOffer")), 1)
      ]),
      default: _withCtx(({ data: noteTaker }) => [
        noteTaker
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("course-occurrence.offer.confirmSendOffer", {
            name: noteTaker.name,
          })), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512),
    _createVNode(_component_ConfirmationModal, {
      ref: "cancelOfferModal",
      onConfirm: _cache[3] || (_cache[3] = ({ offerId }) => _ctx.cancelOffer(offerId))
    }, {
      header: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("course-occurrence.offer.cancelOffer")), 1)
      ]),
      default: _withCtx(({ data: noteTaker }) => [
        noteTaker
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("course-occurrence.offer.confirmCancelOffer", {
            name: noteTaker.name,
          })), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LuSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_DetailsHeader, {
        title: _ctx.$t('shared.routes.course-occurrence-details'),
        onBack: _cache[4] || (_cache[4] = ($event: any) => (_ctx.navigateToOverview())),
        onPrevious: _cache[5] || (_cache[5] = ($event: any) => (_ctx.navigateToCourseOccurrence(_ctx.previousCourseOccurrenceId))),
        onNext: _cache[6] || (_cache[6] = ($event: any) => (_ctx.navigateToCourseOccurrence(_ctx.nextCourseOccurrenceId)))
      }, null, 8, ["title"]),
      _createElementVNode("form", _hoisted_6, [
        (_ctx.occurrence)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_ctx.isRemovedFromLadok)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.notAvailableReason), 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_DetailsRow, {
                caption: "course-occurrence.occurrence",
                details: _ctx.replaceUnvailableOrFormatDate(_ctx.occurrence.occurrence)
              }, null, 8, ["details"]),
              _createVNode(_component_DetailsRow, {
                caption: "course-occurrence.title",
                details: _ctx.replaceUnvailableOrFormatDate(_ctx.courseTitle)
              }, null, 8, ["details"]),
              _createVNode(_component_DetailsRow, {
                caption: "course-occurrence.courseStartDate",
                details: _ctx.replaceUnvailableOrFormatDate(_ctx.occurrence.courseStartDate)
              }, null, 8, ["details"]),
              _createVNode(_component_DetailsRow, {
                caption: "course-occurrence.courseEndDate",
                details: _ctx.replaceUnvailableOrFormatDate(_ctx.occurrence.courseEndDate)
              }, null, 8, ["details"]),
              _createVNode(_component_DetailsRow, {
                caption: "course-occurrence.faculty",
                details: _ctx.replaceUnvailableOrFormatDate(_ctx.faculty)
              }, null, 8, ["details"]),
              _createVNode(_component_DetailsRow, {
                caption: "course-occurrence.institution",
                details: _ctx.replaceUnvailableOrFormatDate(_ctx.institution)
              }, null, 8, ["details"]),
              _createVNode(_component_DetailsRow, {
                caption: "course-occurrence.status",
                details: _ctx.occurrence.status,
                showDetailsAsBadge: true,
                badgeBackgroundColor: _ctx.statusBgColor(_ctx.occurrence.status),
                showTooltip: true,
                tooltipText: _ctx.statusText(_ctx.occurrence.status),
                tooltipColor: _ctx.statusBgColor(_ctx.occurrence.status),
                tooltipTextColor: _ctx.statusTextColor(_ctx.occurrence.status)
              }, null, 8, ["details", "badgeBackgroundColor", "tooltipText", "tooltipColor", "tooltipTextColor"]),
              _createVNode(_component_DetailsRow, { caption: "shared.coordinator" }, {
                default: _withCtx(() => [
                  (_ctx.editEnable)
                    ? (_openBlock(), _createBlock(_component_SelectItem, {
                        key: 0,
                        id: "coordinator",
                        value: _ctx.occurrence.coordinatorId,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.occurrence.coordinatorId) = $event)),
                        name: _ctx.occurrence.coordinatorName,
                        "onUpdate:name": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.occurrence.coordinatorName) = $event)),
                        options: _ctx.coordinators,
                        placeholder: `- ${_ctx.$t('shared.choose')} -`
                      }, null, 8, ["value", "name", "options", "placeholder"]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.occurrence.coordinatorName), 1))
                ]),
                _: 1
              }),
              _createVNode(_component_DetailsRow, { caption: "course-occurrence.students" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Table, { rows: _ctx.studentRows }, {
                    td: _withCtx((data) => [
                      (data.col === 'name')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("person.name")), 1))
                        : _createCommentVNode("", true),
                      (data.col === 'personalId')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t("person.personalId")), 1))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx((data) => [
                      (data.col === 'name')
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: { name: 'StudentDetails', params: { id: data.row.id } }
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(data.val), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(data.val), 1))
                    ]),
                    rowActionEnd: _withCtx((data) => [
                      _createElementVNode("button", {
                        disabled: _ctx.studentOrNoteTakerBtnDisabled,
                        type: "button",
                        class: "btn btn-outline-secondary",
                        onClick: ($event: any) => (_ctx.$refs.deleteStudentModal.open(data.row))
                      }, _toDisplayString(_ctx.$t("course-occurrence.remove-student")), 9, _hoisted_14)
                    ]),
                    _: 1
                  }, 8, ["rows"])
                ]),
                _: 1
              }),
              _createVNode(_component_DetailsRow, { caption: "course-occurrence.noteTakers" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Table, { rows: _ctx.noteTakerRows }, {
                    td: _withCtx((data) => [
                      (data.col === 'name')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t("person.name")), 1))
                        : _createCommentVNode("", true),
                      (data.col === 'personalId')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t("person.personalId")), 1))
                        : _createCommentVNode("", true),
                      (data.col === 'started')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t("course-occurrence.assignment.assignment-start")), 1))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx((data) => [
                      (data.col === 'name')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                            _createVNode(_component_router_link, {
                              to: {
                    name: 'NoteTakerDetails',
                    params: { id: data.row.id },
                  }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(data.val), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ]))
                        : (data.col === 'started')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.formatDate(data.val)), 1))
                          : _createCommentVNode("", true)
                    ]),
                    rowActionEnd: _withCtx((data) => [
                      _createElementVNode("button", {
                        disabled: _ctx.studentOrNoteTakerBtnDisabled,
                        type: "button",
                        class: "btn btn-outline-secondary",
                        onClick: ($event: any) => (_ctx.$refs.deleteNoteTakerModal.open(data.row))
                      }, _toDisplayString(_ctx.$t("course-occurrence.assignment.endAssignment")), 9, _hoisted_20)
                    ]),
                    _: 1
                  }, 8, ["rows"])
                ]),
                _: 1
              }),
              _createVNode(_component_DetailsRow, { caption: "course-occurrence.proposedNoteTakers" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Table, {
                    rows: _ctx.proposedNoteTakersWithoutAssignmentRows,
                    hiddenColumns: ['id', 'offerId']
                  }, {
                    td: _withCtx((data) => [
                      (data.col === 'name')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.$t("person.name")), 1))
                        : (data.col === 'personalId')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.$t("person.personalId")), 1))
                          : (data.col === 'interest')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.$t("shared.interest")), 1))
                            : (data.col === 'offerSent')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.$t("course-occurrence.offer.offerSent")), 1))
                              : (data.col === 'offerCanceled')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.$t("course-occurrence.offer.offerCanceled")), 1))
                                : _createCommentVNode("", true)
                    ]),
                    default: _withCtx((data) => [
                      (data.col === 'name')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
                            _createVNode(_component_router_link, {
                              to: {
                    name: 'NoteTakerDetails',
                    params: { id: data.row.id },
                  }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(data.val), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ]))
                        : _createCommentVNode("", true),
                      (data.col === 'interest')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_ctx.formatDate(data.val)), 1))
                        : _createCommentVNode("", true),
                      (data.col === 'offerSent')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_28, [
                            (!data.val || data.row.offerCanceled)
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  disabled: _ctx.studentOrNoteTakerBtnDisabled,
                                  type: "button",
                                  class: "btn btn-outline-secondary",
                                  onClick: ($event: any) => (_ctx.$refs.sendOfferModal.open(data.row))
                                }, _toDisplayString(_ctx.$t("course-occurrence.offer.sendOffer")), 9, _hoisted_29))
                              : (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_ctx.formatDate(data.val)), 1))
                          ]))
                        : _createCommentVNode("", true),
                      (data.col === 'offerCanceled')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_31, [
                            (!data.val)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  (data.row.offerSent)
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        disabled: _ctx.studentOrNoteTakerBtnDisabled,
                                        type: "button",
                                        class: "btn btn-outline-secondary",
                                        onClick: ($event: any) => (_ctx.$refs.cancelOfferModal.open(data.row))
                                      }, _toDisplayString(_ctx.$t("course-occurrence.offer.cancelOffer")), 9, _hoisted_32))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_ctx.formatDate(data.val)), 1))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["rows"])
                ]),
                _: 1
              }),
              (_ctx.noteTakerUploads.length > 0)
                ? (_openBlock(), _createBlock(_component_DetailsRow, {
                    key: 1,
                    caption: "course-occurrence.uploads.title"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Table, {
                        rows: _ctx.noteTakerUploads,
                        hiddenColumns: ['noteTakerId']
                      }, {
                        td: _withCtx((data) => [
                          (data.col === 'noteTakerName')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_ctx.$t("course-occurrence.uploads.note-taker")), 1))
                            : (data.col === 'totalUploads')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(_ctx.$t("course-occurrence.uploads.number-of-files")), 1))
                              : (data.col === 'lastUpload')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(_ctx.$t("course-occurrence.uploads.latest-upload")), 1))
                                : _createCommentVNode("", true)
                        ]),
                        default: _withCtx((data) => [
                          (data.col === 'noteTakerName')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_37, [
                                _createVNode(_component_router_link, {
                                  to: {
                    name: 'NoteTakerDetails',
                    params: { id: data.row.noteTakerId },
                  }
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(data.val), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"])
                              ]))
                            : _createCommentVNode("", true),
                          (data.col === 'totalUploads')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(data.val), 1))
                            : _createCommentVNode("", true),
                          (data.col === 'lastUpload')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_39, _toDisplayString(_ctx.formatDate(data.val)), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["rows"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_DetailsRow, null, {
                default: _withCtx(() => [
                  _createVNode(_component_EditButtonsGroup, {
                    ref: "editButtonsGroup",
                    editEnable: _ctx.editEnable,
                    "onUpdate:editEnable": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editEnable) = $event)),
                    saveBtnDisabled: _ctx.saveBtnDisabled,
                    onOnCancelClicked: _ctx.cancelCoordinatorUpdate,
                    onOnSaveClicked: _ctx.updateCourseOccurrence
                  }, null, 8, ["editEnable", "saveBtnDisabled", "onOnCancelClicked", "onOnSaveClicked"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}